<template>
  <div class="wrapper tw-flex md:tw-flex-row tw-flex-col tw-items-center mt-md-n16">
    <img src="@/assets/empty-order-img.svg" alt="frame">
    <div class="md:tw-ml-12 tw-mt-4 tw-text-center md:tw-text-left">
      <h4>You don’t have any {{text}}</h4>
      <p>Design and print custom merch superfast!</p>
      <slot></slot>
    </div>


  </div>
</template>

<script>
export default {
    name:"EmptyStateForOrderOrDesign",
props:{
  text:{
    type: String,
    default: "orders"
  }
}

}
</script>

<style lang="scss" scoped>
.wrapper{
 width: 100%;
 min-height: 300px;
 h4{
   font-family: 'DM Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   line-height: 32px;
   letter-spacing: -0.03em;
   color: #514A41;
 }
  p{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7B7777;
    padding-top: 6px;
  }
}
.button{
  padding: 12px 20px;
  min-width: 128px;
  width: auto;
  height: 42px;
  background: #FF7A01;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  margin-top: 8px;
}
</style>
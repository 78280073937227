<template>
    <v-row justify="center">
      <v-dialog
        v-model="passwordDialog"
        persistent
        max-width="415"
        width="415"
        :fullscreen="$vuetify.breakpoint.mobile"
      >

        <v-card class="tw-px-8 tw-py-6 card ">
          <div class="tw-flex tw-justify-between">
            <h3 class="text-h5">
              Change Password
            </h3>
            <v-icon @click="close()" class="tw-cursor-pointer">mdi-window-close</v-icon>
          </div>

            <div class="tw-mt-8">
              <ValidationObserver ref="observer" v-slot="{ validate, invalid }"  @submit.prevent="validate().then(changePassword)" class="tw-w-full lg:tw-w-3/5 tw-flex-col lg:tw-pr-20">

                <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                  <label class="shipping-address-label tw-pb-1">Old Password</label>
                  <ValidationProvider
                    name="old password"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      id="old"
                      color="#FF7A01"
                      height="44"
                      outlined
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'text' : 'password'"
                      v-model="old_password"
                      style="border-radius: 10px"></v-text-field>
                    <span class="err">{{ errors[0] }}</span>

                  </ValidationProvider>
                </div>
              <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                <label class="shipping-address-label tw-pb-1">New Password</label>
                <ValidationProvider
                  name="new password"
                  :rules="{
                  required: true,
                  regex:  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                  min: 8
                }"
                  vid="password"
                  v-slot="{ errors }">
                  <v-text-field
                    id="new"
                    color="#FF7A01"
                    height="44"
                    outlined
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'text' : 'password'"
                    v-model="password"
                    style="border-radius: 10px"></v-text-field>
                  <span class="err">{{ errors[0] ? 'Password must contain at least one uppercase, lowercase, digit, special character and must be at least 8 character' : '' }}</span>

                </ValidationProvider>
              </div>
              <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
                <label class="shipping-address-label tw-pb-1">Confirm Password</label>
                <ValidationProvider
                  name="confirm password"
                  rules="required|confirmed:password"
                  v-slot="{ errors }">
                  <v-text-field
                    id="confirm-password"
                    color="#FF7A01"
                    height="44"
                    outlined
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'text' : 'password'"
                    data-vv-as="password"
                    v-model="confirm_password"
                    style="border-radius: 10px"></v-text-field>
                  <span class="err">{{ errors[0] ? 'Password mismatch' : '' }}</span>

                </ValidationProvider>
              </div>
                <v-card-actions class="tw-mt-6">
                  <v-spacer></v-spacer>
                  <v-btn class="proceed-btn tw-mr-3" width="115" height="42" outlined color="#FF7A01"  @click="close()">
                    Cancel
                  </v-btn>
                  <v-btn class="proceed-btn tw-mr-3" width="115" height="42" style="background-color: #FF7A01" text @click="changePassword()" :disabled="invalid" >
                    {{submitting ? 'Saving...' : 'Save'}}
                  </v-btn>
                </v-card-actions>

              </ValidationObserver>
            </div>

        </v-card>
      </v-dialog>
    </v-row>
</template>


<script>
import {changePassword} from "@/services/auth-services"
export default {
  name: "ChangePassword",
  data(){
    return{
      value : false,
      password: "",
      old_password:"",
      confirm_password:"",
      submitting: false
    }
  },
  props:{
    passwordDialog:false

  },
  methods:{
    changePassword(){
      if(this.password !== this.confirm_password){
        return false
      }
      this.submitting = true

      let data = {
        password: this.password,
        old_password: this.old_password,
        password2: this.confirm_password
      }
    changePassword(data).then(res => {
      this.$displaySnackbar({success: true, message: res.data.detail})
      this.submitting = false
      this.close()
      })
      .catch(error => {
              this.$displaySnackbar({success: false, message: error.response.data.old_password.old_password})
              this.submitting = false
          })
    
    },
    close(){
      this.password = ""
      this.old_password = ""
      this.confirm_password = ""
      this.$refs.observer.reset()
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.shipping-address-width {
  width: 48%;
}
.card{

  background: #FFFFFF;
  box-shadow: 4px 16px 42px rgba(99, 47, 0, 0.24);
  border-radius: 24px;
  h3{
  font-family: 'DM Sans' !important;
  }
}
.change-btn{
  background: #FFF8EF;
  border-radius: 100px;
  padding: 12px 20px;
}
.proceed-btn {
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
@media screen and (max-width: 1024px) {
  width: 100%;
}
}
h3{
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: -0.02em;
  color: #00050D;
}
span{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #000000;

}
.v-dialog--fullscreen > .v-card{
  @media screen and (max-width: 1024px) {
    padding: 20px !important;
  }
}
.err{
  font-size: 12px;
  color: #FF9494
}
.theme--light.v-btn.v-btn--disabled {
  background-color: #E0DEDC !important;
  color: #FFFFFF !important;
}
</style>

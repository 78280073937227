<template>
  <div>
    <div class="wrapper tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-items-center tw-cursor-pointer"  @click="goToOrderDetailsPage(orders.id)">
      <div class="tw-flex tw-flex-row md:tw-flex-col tw-justify-between tw-mb-4 md:tw-mb-0">
        <div class="tw-flex md:tw-w-40">
          <div>
<!--            <div v-for="(item,i) in orders.categories" class="order-text" :key="i">-->
            <div  class="order-text">
              <div v-if="orders.categories.length >=1">
                <h3>{{orders.categories[0]}} </h3>
              </div>
            </div>
            <p class="price"><span>&#8358;</span> {{ formatPrice(orders.amount) }}</p>
          </div>
          <div v-if="orders.order_items.length > 1" class="order-text tw-pl-1">
            <h3> x{{ orders.order_items.length}}</h3>
          </div>
        </div>
        <div class="tw-flex md:tw-flex-row tw-flex-col">
          <h1 class="order-id">#{{orders.order_id}}</h1>
          <h4 class="complete tw-ml-2">{{orders.status}}</h4>
        </div>
      </div>

      <div class="md:tw-w-36">
<!--        <img src="@/assets/shirts/kneenk-black.svg" width="50px" height="50px"/>-->
        <v-img
            width="80"
            height="100"
            :src="shirt"
        />
      </div>
    </div>

  </div>
</template>

<script>
import priceMixin from "@/mixins/priceMixin";
import {getFrontImage} from "@/store/buildMerch/getters";

export default {
  name: "AllOrder",
  mixins: [priceMixin],
  props:{
    orders:[Object]
  },
  computed:{
    shirt () {
      return getFrontImage(this.orders.images_n_color[0].color_code)
    },
  },
  methods:{
    getCount(sizes){
      return Object.values(sizes).reduce((acc, curr) => curr + acc, 0)
    },
    goToOrderDetailsPage(orderId){
      console.log(orderId)
      this.$router.push({
          name: "OrderDetailsPage",
          params: {
            orderId,
          },
      });
  }
  }
};
</script>

<style scoped lang="scss">
.wrapper{
  background: #FFFFFF;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04);
  padding: 20px 31px 24px;
  margin-bottom: 10px;
}
.type{
  width: 52px;
  height: 26px;
  margin-right: 5px;
  background: #FFF8EF;
  border: 1px solid rgba(255, 122, 1, 0.32);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #514A41;
}
.order-text{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7B7777;
}
.complete{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #008F6E;
  padding: 7px 7px;
  background: #E7F8F4;
  border-radius: 4px;
}
.price{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #00050D;
}
.order-id{
  background: ghostwhite;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 7px 7px;
  color: #514A41;
}
</style>

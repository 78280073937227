<template>
<div>
<div class="bckg tw-flex tw-justify-center tw-items-center">
  <img :src="order.image" style="width: 200px !important; height: 130px!important;">
</div>
  <div>
    <h2 class="tw-mt-6 tw-mb-3">{{order.merch_name}}</h2>
    <div class="tw-flex">
      <div class="tw-flex tw-items-center">
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.73338 0.623688L0.876953 3.21739L2.39457 4.97736L3.73338 3.76629V11.6686H11.0495V3.76629L12.4598 4.97736L13.9656 3.21739L11.0495 0.623688H9.09477L8.93545 0.783001C8.13234 1.58611 6.82244 1.56005 6.0519 0.725639L5.95775 0.623688H3.73338Z" stroke="#514A41"/>
        </svg>
        <span class="tw-ml-2 tw-mr-4">{{order.color}}</span>
      </div>
      <span>Medium</span>

    </div>
    <div class="tw-flex tw-my-4">
      <div class="tw-flex tw-justify-between">
        <div class="actions tw-mr-2">
          Edit
        </div>
        <div class="actions">
          Add to Cart
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="tw-flex tw-items-center menu">
        <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
         <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="deleteDesign(order.id)">
          <v-list-item-title class="tw-flex tw-items-center mr-6">
            <v-icon class="mr-2" color="#AAAAAA" size="18">mdi-trash-can-outline</v-icon> 
           <span> Delete</span>
            </v-list-item-title>
        </v-list-item>
      </v-list>
        </v-menu>
        
      </div>
    </div>
</div>
</div>
</template>

<script>
export default {
  name: "SavedOrder",
  props:{
    order:[Object]
  },
  methods:{
    async deleteDesign(saved_design_id){
      console.log(saved_design_id)
     await this.$store.dispatch("profile/deleteSavedDesign", saved_design_id)
    }

  }
};
</script>

<style scoped lang="scss">
.bckg{
  background: #F4F6F7;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;

}
.actions{
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
cursor: pointer;
  color: #00050D;

}
h2{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #00050D;

}
span{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #514A41;
}
.menu ::v-deep .v-list-item{
    min-height: 36px !important;
  }
.menu{
  span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #292929;
  }
}
</style>

<template>
<div>
  <LandingPageHeader/>
  <div id="background" :style="{background:`url(${wallpaper}) no-repeat`,backgroundSize:'cover'}"
       class="tw-h-28 tw-w-full tw-bg-no-repeat">
    <div class="background_cover_image">
      <img
        alt="cover_image"
        width="100%"
        :src="wallpaper"
      />
    </div>
    <div class="profile tw-pl-5  md:tw-pl-24">
      <div class="tw-flex tw-items-center">
        <span>Welcome!</span>
      </div>

    </div>
  </div>
  <v-row no-gutters class="tw-w-10/12 tw-m-auto mx-auto md:tw-px-0  tw-px-6 mt-16" style="min-width: 380px;">
    <v-col cols="12" md="3" sm="12">
      <ProfileSideActionCard/>

    </v-col>
    <v-col cols="12" md="1" sm="0" class="tw-hidden md:tw-block">

    </v-col>
    <v-col cols="12" md="8" sm="12" class="md:tw-mt-4 tw-mt-16 tw-ml-2 md:tw-ml-0">
      <div v-if="loading" class="tw-flex mt-n16 tw-justify-center tw-items-center tw-h-screen">
        <Loader/>
      </div>
      <div v-else>
        <p class="order-text">Orders</p>
        <div>
          <div class="tw-flex tw-pb-8 tw-mt-6 md:tw-mt-0 flex-wrap">
            <simple-tabs v-model="itemToDisplay" :tabs="tabs" />
          </div>
          <div>
            <div>
              <div v-if="itemToDisplay==='all'">
                <div>
                  <div>
                    <div v-if="allOrders.length === 0">
                      <empty-state-for-order-or-design>
                        <template>
                          <div>
                            <v-btn text rounded class="button tw-mb-8 md:tw-mb-0" @click="$router.push('/build')">CUSTOM MERCH</v-btn>
                          </div>
                        </template>
                      </empty-state-for-order-or-design>
                    </div>
                    <div v-else>
                      <div>
                        <AllOrder v-for="(data,x) in visibleOrders" :key="x" :orders="data"/>
                        <div class="tw-mb-8 tw-mt-4 md:tw-ml-96 tw-ml-36" v-if="!allItemsOrders">
                          <v-btn class="see-more" color="#FF7A01" rounded text @click="showMoreOrders" v-if="visibleOrders || allOrders.length === 0">
                            SHOW MORE
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div v-if="itemToDisplay==='active'">
                <div v-if="activeOrder.length === 0" class="md:tw-mt-20 tw-mb-8">
                  <div class="wrapper tw-flex md:tw-flex-row tw-flex-col tw-items-center  mt-md-n16">
                    <img src="@/assets/empty-order-img.svg" alt="frame">
                    <div class="md:tw-ml-12 tw-mt-4 tw-text-center md:tw-text-left">
                      <h4>You have no active orders</h4>
                    </div>
                  </div>
                </div>
                <AllOrder v-for="(data,i) in activeOrder" :key="i" :orders="data"/>
              </div>

              <div v-if="itemToDisplay==='completed'">
                <div v-if="completedOrders.length === 0" class="md:tw-mt-20 tw-mb-12">
                  <div class="wrapper tw-flex md:tw-flex-row tw-flex-col tw-items-center  mt-md-n16">
                    <img src="@/assets/empty-order-img.svg" alt="frame">
                    <div class="md:tw-ml-12 tw-mt-4 tw-text-center md:tw-text-left">
                      <h4>You don’t have any completed orders yet</h4>
                    </div>
                  </div>
                </div>
                <AllOrder v-for="(data,i) in completedOrders" :key="i" :orders="data"/>
              </div>
            </div>
          </div>
        </div>
      </div>


<!--      <div v-if="itemToDisplay==='saved designs'" >-->
<!--        <div v-if="allSavedDesigns.length === 0">-->
<!--          <empty-state-for-order-or-design text="saved designs"/>-->
<!--        </div>-->

<!--        <div v-else class="tw-grid md:tw-grid-cols-3 tw-gap-4 tw-mt-8">-->
<!--        <SavedOrder v-for="(data,i) in allSavedDesigns" :key="i" :order="data"/>-->
<!--        &lt;!&ndash; <SavedOrder/>-->
<!--        <SavedOrder/> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->

<!--      <div>-->
<!--      </div>-->

<!--      <div v-else-if="itemToDisplay === 'campaigns'">-->
<!--        <campaign-tab-component />-->
<!--      </div>-->

    </v-col>

  </v-row>
  <div class="tw-block md:tw-hidden floating-btn tw-shadow-2xl">
<!--    <build-merch-button/>-->
    <v-btn text  rounded to="/build" class="merch"  >BUILD MERCH</v-btn>

  </div>
</div>
</template>

<script>
import LandingPageHeader from "@/components/LandingPageHeader";
import ProfileSideActionCard from "@/components/profile/ProfileSideActionCard";
import ActiveOrders from "@/components/profile/ActiveOrders";
import AllOrder from "@/components/profile/AllOrder";
import SavedOrder from "@/components/profile/SavedOrder";
import {mapGetters} from "vuex";
import EmptyStateForOrderOrDesign from '@/components/profile/EmptyStateForOrderOrDesign.vue';
import CampaignTabComponent from "@/components/profile/campaigns/CampaignTabComponent";
import SimpleTabs from "@/components/SimpleTabs";
import BuildMerchButton from "@/reuseable/BuildMerchButton.vue";
import Loader from "@/reuseable/Loader.vue";
export default {
  name: "ProfileLayout",
  components: {
    Loader,
    BuildMerchButton, SimpleTabs, CampaignTabComponent, SavedOrder, AllOrder, ActiveOrders, ProfileSideActionCard, LandingPageHeader,EmptyStateForOrderOrDesign },
  data(){
    return{
      wallpaper: require('@/assets/welcomeBanner.png'),
      itemToDisplay:'All',
      // tabs: ['my orders', 'saved designs', 'campaigns'],
      tabs: ['all', 'active', 'completed'],
      visibleOrders: [],
      activeOrder:[],
      completedOrders: [],
      loading: false

    }
  },
  watch: {
    itemToDisplay: {
      handler: function(value) {
        this.$router.push({query: {tab: value}})
      }
    }
  },
  async created(){
    this.itemToDisplay = this.$route.query?.tab ?? 'all'
    await this.getUserOrder()
    await this.$store.dispatch("profile/getAllSavedDesigns")

    this.visibleOrders = this.allOrders.slice(0, 10);
    this.activeOrder = this.allOrders.filter((item) => item.status !== 'completed' && item.status !== 'cancelled')
    this.completedOrders = this.allOrders.filter((item) => item.status === 'completed')
  },
  computed:{
    ...mapGetters('profile',['allOrders', 'allSavedDesigns']),

    allItemsOrders() {
      return this.visibleOrders.length === this.allOrders.length;
    },
  },
  methods:{
    showMoreOrders() {
      this.loading = true
      this.visibleOrders = this.allOrders;
      this.loading = false
    },
   async getUserOrder(){
      this.loading = true
      await this.$store.dispatch("profile/getAllUserOrders")
      this.loading = false
    },
  }
};
</script>

<style scoped lang="scss">
#background {
  position: relative;
  overflow-y: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}

.profile {
  outline: none;
  position: absolute;
  z-index: 2;
  margin: inherit !important;
}
.profile span{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  margin-top: 1.5rem;
  margin-left: 2rem;
}
.v-btn{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: none!important;
}
.order-text{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #514A41;
}
.see-more{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FF7A01;
  border: 1px solid #FF7A01;
  padding: 12px 20px;
  width: 129px;
  height: 42px;
  border-radius: 100px;
}
h4{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #514A41;
}
.floating-btn {
  position: fixed;
  bottom: 32px;
  right: 20px;
}
.merch{
  padding: 8px 16px;
  min-width: 128px;
  width: auto;
  height: 34px;
  background: #FF7A01;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  text-transform: capitalize;
  box-shadow: 0 5px 8px rgb(255, 149, 61);
}
</style>

<template>
    <v-row justify="center">
      <v-dialog
        v-model="editDialog"
        persistent
        max-width="415"
        width="415"
        :fullscreen="$vuetify.breakpoint.mobile"
      >

        <v-card class="tw-px-8 tw-py-6 card ">
          <div class="tw-flex tw-justify-between">
            <h3 class="text-h5">
              Edit Profile
            </h3>
            <v-icon @click="$emit('close')" class="tw-cursor-pointer">mdi-window-close</v-icon>
          </div>

          <div class="tw-mt-4">
            <span>Profile Photo</span>
            <div class="tw-flex tw-items-center tw-justify-between tw-mt-4">
              <div>
                <v-img width="41" height="41" style="border-radius: 50%" :src="profileInfo.image"  >
                </v-img>
              </div>

              <div class="tw-my-2 ">
              <div class="tw-flex">
                <input
                    id="assetsFieldHandle"
                    ref="file"
                    accept="image/png, image/jpeg,image/jpg"
                    class="opacity-0 overflow-hidden absolute"
                    name="fields[assetsFieldHandle][]"
                    style="display: none"
                    type="file"
                    @change="uploadToCloudinary()"/>
                <label class=" tw-cursor-pointer "
                      for="assetsFieldHandle">
                      <div class="tw-flex tw-items-center">
                        <span class="mr-2" v-if="file"> {{file.name}}</span>
                        <span class="mr-2" v-else> Choose from files</span>
                        <div class="change-btn">
                          <span  v-if="file">Change</span>
                          <span v-else>Upload</span>
                      </div>
                      </div>

                </label>
              </div>
              </div>

            </div>
            <div class="tw-mt-8">
              <ValidationObserver ref="observer" v-slot="{ validate, invalid }" @submit.prevent="validate().then(editProfile)"  class="tw-w-full lg:tw-w-3/5 tw-flex-col lg:tw-pr-20">

              <div class="tw-flex tw-w-full tw-justify-between tw-flex-row ">
              <div class="tw-flex tw-flex-col shipping-address-width">
                <label class="shipping-address-label tw-pb-1">First Name</label>
                <ValidationProvider
                  name="first Name"
                  rules="required"
                  v-slot="{ errors }">
                  <v-text-field
                    id="firstName"
                    color="#FF7A01"
                    height="44"
                    outlined
                    v-model="profileInfo.first_name"
                    style="border-radius: 10px"></v-text-field>
                  <span class="err">{{ errors[0] }}</span>

                </ValidationProvider>
              </div>
              <div class=" tw-flex tw-flex-col shipping-address-width ">
                <label class="shipping-address-label tw-pb-1">Last Name</label>
                <ValidationProvider
                  name="last Name"
                  rules="required"
                  v-slot="{ errors }">
                  <v-text-field
                    id="lastName"
                    color="#FF7A01"
                    height="44"
                    outlined
                    v-model="profileInfo.last_name"

                    style="border-radius: 10px"></v-text-field>
                  <span class="err">{{ errors[0] }}</span>

                </ValidationProvider>
              </div>
              </div>
<!--              <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">-->
<!--                <label class="shipping-address-label tw-pb-1">Phone</label>-->
<!--                <ValidationProvider-->
<!--                  name="phone"-->
<!--                  rules="required|numeric"-->
<!--                  v-slot="{ errors }">-->
<!--                  <v-text-field-->
<!--                    id="phone"-->
<!--                    color="#FF7A01"-->
<!--                    height="44"-->
<!--                    outlined-->
<!--                    type="tel"-->
<!--                    v-model="profileInfo.phone_number"-->
<!--                    style="border-radius: 10px"></v-text-field>-->
<!--                  <span class="err">{{ errors[0] }}</span>-->

<!--                </ValidationProvider>-->
<!--              </div>-->
                <v-card-actions class="tw-mt-6 px-0">
                  <v-btn class="proceed-btn tw-mr-3" width="115" height="42" outlined color="#FF7A01"  @click="$emit('close')">
                    Cancel
                  </v-btn>
                  <v-btn class="proceed-btn tw-mr-3" width="115" height="42" style="background-color: #FF7A01" text @click="editProfile()" :disabled="invalid" >

                   {{submitting ? 'Saving...' : 'Save'}}
                  </v-btn>
                </v-card-actions>

              </ValidationObserver>
            </div>

          </div>
        </v-card>
      </v-dialog>
    </v-row>
</template>


<script>
import {mapGetters} from "vuex";
import {getProfileInfo, uploadToCloudinary} from "@/services/user-profile"
import { uploadDocumentToCloudinary } from "@/services/helpers";
export default {
  name: "EditProfile",
  props:{
    editDialog:false
  },
  data(){
    return{
      file:"",
      profileImageInfo:{},
      submitting: false
    }
  },
  computed:{
    ...mapGetters('profile',['profileInfo']),
  },
  methods:{
    async editProfile(){
      this.submitting = true;
      if (this.file){
        try {
          await this.handleImage(this.file);
          this.callEditProfileEndpoint();
          // this.loading = false
        } catch (e) {
          // this.loading = false;
          this.submitting = false;
          return;
        }
      }
      else{
        await this.callEditProfileEndpoint();
      }

    },
    async callEditProfileEndpoint(){
      try {
        await this.$store.dispatch("profile/editProfileInfo", {
          first_name: this.profileInfo.first_name,
          last_name: this.profileInfo.last_name,
          phone_number: this.profileInfo.phone_number,
          image: this.profileImageInfo.image_url || this.profileInfo.image
        })
        this.$refs.observer.reset()
        this.file = ""
        this.$emit('close')
        this.$displaySnackbar({success: true, message: 'Profile updated successfully'})
        const { data } = await getProfileInfo()
        this.$updateUserData(data)
      } catch (e) {
        console.log(e)
      } finally {
        this.submitting = false
      }
    },
    async uploadToCloudinary() {
      // this.loading = true
      this.file = this.$refs.file.files[0];
    },

    async handleImage(file) {
      const {url: image_url, name: image_name, size: image_file_size} = await uploadDocumentToCloudinary(file, "profile-images")
      this.profileImageInfo = {image_url, image_name, image_file_size};
    },


  }
};
</script>
<style lang="scss" scoped>
.shipping-address-width {
  width: 48%;
}
.card{

  background: #FFFFFF;
  box-shadow: 4px 16px 42px rgba(99, 47, 0, 0.24);
  border-radius: 24px;
  h3{
  font-family: 'DM Sans' !important;
  }
}
.change-btn{
  background: #FFF8EF;
  border-radius: 100px;
  padding: 12px 20px;
  span{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FF7A01;

  }
}
.proceed-btn {
  border-radius: 100px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
@media screen and (max-width: 1024px) {
  width: 100%;
}
}
h3{
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: -0.02em;
  color: #00050D;
}
span{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #000000;

}
.v-dialog--fullscreen > .v-card{
  @media screen and (max-width: 1024px) {
    padding: 20px !important;
  }
}
.err{
  font-size: 12px;
  color: #FF9494
}
.theme--light.v-btn.v-btn--disabled {
  background-color: #E0DEDC !important;
  color: #FFFFFF !important;
}

</style>

<template>
  <div
    class="tw-flex tw-flex-col tw-mt-10 tw-mb-5 sm:tw-flex-row tw-items-center tw-justify-center"
  >
    <img
      class="tw-w-2/5 sm:tw-w-auto"
      src="@/assets/no-campaign.svg"
      alt="no campaign image"
    />
    <div class="sm:tw-ml-10 tw-mt-6 sm:tw-mt-0 tw-text-center sm:tw-text-left">
      <div class="no-campaign-title">No campaign? No problem.</div>
      <div class="no-campaign-subtitle">
        Looks like you have not created any campaign yet.
      </div>
      <v-btn
        rounded
        :to="{ name: 'CreateCampaign' }"
        :width="!$vuetify.breakpoint.xs ? 175 : undefined"
        :block="$vuetify.breakpoint.xs"
        height="42"
        class="text-uppercase ml-n2 tw-mt-6"
        id="create-btn"
        depressed
        color="primary"
        >CREATE CAMPAIGN</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NoCampaignPage",
};
</script>

<style lang="scss" scoped>
.no-campaign-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #514a41;
}
.no-campaign-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #514a41;
}
</style>
<template>
  <div class="side-card">
    <div class="tw-flex tw-my-4">
      <div class="tw-mr-3">
        <v-img width="41" height="41" style="border-radius: 50%" :src="profileImage" >

        </v-img>

      </div>
      <div>
        <h1>{{profileInfo.first_name || userProfile.first_name }}</h1>
        <span>{{userProfile.email}}</span>
      </div>
    </div>

    <div class="tw-pt-6 tw-pb-4">
      <h3>ACCOUNT SETTINGS</h3>

    </div>
    <div @click="editDialog=!editDialog" class="tw-flex profile-action tw-justify-between">
      <h2>Edit Profile</h2>
      <v-icon>mdi-arrow-right</v-icon>

    </div>
    <div @click="passwordDialog=!passwordDialog" class="tw-flex profile-action tw-justify-between">
      <h2>Change Password</h2>
      <v-icon>mdi-arrow-right</v-icon>

    </div>
    <EditProfile @close="editDialog=false" :edit-dialog="editDialog"/>
    <ChangePassword @close="passwordDialog=false" :password-dialog="passwordDialog"/>

  </div>

</template>

<script>
import EditProfile from "@/components/profile/EditProfile";
import ChangePassword from "@/components/profile/ChangePassword";
import {mapGetters} from "vuex";
export default {
  name: "ProfileSideActionCard",
  components: { ChangePassword, EditProfile },
  data(){
    return{
      editDialog: false,
      passwordDialog: false,
      userProfile:{}

    }
  },
  async created(){
    this.userProfile = JSON.parse(localStorage.getItem('userData'))
    await this.$store.dispatch("profile/getProfileInfo")
  },
  computed:{
    ...mapGetters('profile',['profileInfo']),
    profileImage(){
      // if (this.profileInfo) return this.profileInfo.image;
      return this.$getUserData.fromLocalStorage()?.image || 'https://res.cloudinary.com/spacehubpictures/image/upload/v1657317163/kneek/kqjxlana8u4kaikstsrw.png'
    }
  },
  methods:{

  }
};
</script>

<style scoped lang="scss">
.profile-action{
  background: #FFFFFF;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
}
.side-card{
  h1{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00050D;
    text-transform: capitalize;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #00050D;

  }
  h3{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #514A41;

  }
  h2{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #514A41;
  }
}

</style>

<template>
  <div class="campaign-tab-component">
    <h4 class="campaign-title mb-4">Campaigns</h4>
    <template v-if="campaigns.length === 0">
      <no-campaign-page/>
    </template>
    <div v-else>
      <div class="d-flex justify-space-between align-center">
        <div style="max-width: 400px; width: 100%">
          <v-text-field :disabled="campaigns.length === 0" v-model="search" class="search-field" solo hide-details append-icon="mdi-magnify" label="Search Campaigns" />
        </div>
        <v-btn rounded :to="{ name: 'CreateCampaign' }" :width="!$vuetify.breakpoint.xs ? 94: undefined" :fixed="$vuetify.breakpoint.xs" :block="$vuetify.breakpoint.xs" :bottom="$vuetify.breakpoint.xs" height="42" class="text-uppercase ml-n2" id="create-btn" depressed color="primary">Create</v-btn>
      </div>
      <div class="d-flex justify-center mt-6" v-if="loading">
        <v-progress-circular color="primary" indeterminate />
      </div>
      <div class="mt-6" v-else v-if="campaigns.length > 0">
        <span>ALL ({{campaigns.length}})</span>
        <div>
          <campaign-card
            v-for="(campaign, i) in campaigns"
            :key="i"
            :campaign="campaign"
            class="mt-6"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignCard from "@/components/profile/campaigns/CampaignCard"
import NoCampaignPage from './NoCampaignPage.vue';
export default {
  name: "CampaignTabComponent",
  components: {
    CampaignCard,
    NoCampaignPage
  },
  data: () => ({
    search: '',
    campaigns: [],
    loading: false
  }),
  async created() {
    try {
      this.loading = true
      const { data } = await this.$axios(`events/event`)
      this.campaigns = data.map(campaign => ({
        id: campaign.id,
        attendees: campaign.attendee_count,
        status: campaign.status,
        endDate: campaign.event_date,
        name: campaign.name,
        link: '',
        eventCode: campaign.event_code
      }))
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  &.campaign-tab-component {
    max-width: 713px
  }

  .campaign-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    letter-spacing: -0.02em;

    /* Brown/500 */

    color: #514A41;
  }


  .v-btn {
    &.text-uppercase {
      text-transform: uppercase !important;
    }

    &--fixed {
      z-index: 2;
      max-width: 400px;
      min-width: 89% !important;
    }
  }

  .search-field {
    .v-input__slot {
      box-shadow: none !important;
      border: 1px solid #EAEAEA !important;
      border-radius: 10px !important;
    }
  }


}
</style>

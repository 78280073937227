<template>
  <v-card class="campaign-card-wrap" width="713" height="84" :to="{ name: 'CampaignDetails', params: { id: campaign.id } }">
    <v-card-actions class="d-flex align-center justify-space-between pa-5">
      <div>
        <h5 class="campaign-card-title">{{ campaign.name }}</h5>
        <div class="d-flex align-center">
          <span>{{ campaign.attendees }} Attendee{{ campaign.attendees > 1 ? 's' : '' }}</span>
          <v-icon>mdi-circle-small</v-icon>
          <span>{{ campaign.status }}</span>
          <v-icon color="#9A9595">mdi-circle-small</v-icon>
          <span>Ends on {{ this.$dayjs(campaign.endDate).format('DD MMMM') }}</span>
        </div>
      </div>
      <div>
        <v-btn icon color="#F9F4EE" :to="{name: 'Event', query: {code: campaign.eventCode}}" class="link-icon">
          <v-icon color="black">mdi-link-variant</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CampaignCard",
  props: {
    campaign: {
      type: Object,
      required: true,
      validator: (value) => [
        'id',
        'name',
        'attendees',
        'status',
        'endDate',
        'link'
      ].every(prop => value.hasOwnProperty(prop))
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  &.v-card {
    border: 1px solid #FAFAFA;
    box-shadow: 0 12px 24px rgba(173, 173, 173, 0.08), 0 2px 4px rgba(173, 173, 173, 0.04) !important;

    button.link-icon {
      background: #F9F4EE
    }

    .campaign-card-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */


      /* Brown/500 */

      color: #514A41;

      &+div {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        /* Grey/400 */

        color: #9A9595;
      }
    }
  }
}
</style>

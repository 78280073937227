<template>
<div>
  <ProfileLayout/>
</div>
</template>

<script>
import ProfileLayout from "@/components/profile/ProfileLayout";
export default {
  name: "Profile",
  components: { ProfileLayout }
};
</script>

<style scoped>

</style>

<template>
<div>
  <div class="active tw-cursor-pointer" @click="$router.push({name:'OrderDetailsPage'})">
    <div class="tw-flex tw-justify-between tw-mb-4">
      <h1>{{order.id}}</h1>
      <h2>Total Qty: {{order.total_qty}}</h2>

    </div>
    <div class="tw-flex" >
      <div  v-for="(data,i) in order.items" :key="i">
      <div class="type" v-if="i<=1">
        <h3>{{data}}</h3>
      </div>
<!--      <div class="type">-->
<!--        <h3>Polo</h3>-->
<!--      </div>-->

      </div>
      <div v-if="order.items.length > 2" class="type">
        <h3> +{{ order.items.length-2}}</h3>
      </div>
    </div>
    <div  class="tw-flex tw-justify-between tw-items-center">
      <div>
        <img src="@/assets/shirts/kneenk-black.svg" width="40px" height="40px"/>
      </div>
      <div class="process">
        <h4>{{order.status}}</h4>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "ActiveOrders",
  props:{
    order:[Object]
  }
};
</script>

<style scoped lang="scss">
.active{
  background: #FFFFFF;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04);
  border-radius: 20px;
  padding: 25px;
  margin-right: 10px;

  h1{
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #514A41;
  }
  h2{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #514A41;
  }
  h3{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #514A41;

  }

}
.type{
  width: 52px;
  height: 26px;
margin-right: 5px;
  background: #FFF8EF;
  border: 1px solid rgba(255, 122, 1, 0.32);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;

}
.process{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #514A41;
  background: #F3F3F3;
  border-radius: 4px;
  padding: 7px;
}

</style>
